import toast from "react-hot-toast";

const Toast = (severity, message) => {
  if (severity === "success") {
    toast.success(message, {
      // custom styles for react-hot-toast that could be called anywhere
      style: {
        background: "#388E3C",
        padding: "16px",
        color: "#ffffff",
      },
      iconTheme: {
        primary: "#388E3C",
        secondary: "#ffffff",
      },
      duration: 5000,
    });
  } else if (severity === "error") {
    toast.error(message, {
      // custom styles for react-hot-toast that could be called anywhere
      style: {
        background: "#D32F2F",
        padding: "16px",
        color: "#ffffff",
      },
      iconTheme: {
        primary: "#D32F2F",
        secondary: "#ffffff",
      },
      duration: 5000,
    });
  } else {
    toast.loading(message, {
      // custom styles for react-hot-toast that could be called anywhere
      style: {
        background: "#0288D1",
        padding: "16px",
        color: "#ffffff",
      },
      iconTheme: {
        primary: "#0288D1",
        secondary: "#ffffff",
      },
      duration: 5000,
    });
  }
};

export default Toast;
