import axios from "axios";
import { appConfig } from "../config";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = appConfig.API_URL;

// Request Interceptor. All Request pass from here
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    // if auth
    // const authToken = localStorage.getItem("accessToken");
    // if (authToken) {
    //   axiosConfig.headers["Authorization"] = `Bearer ${authToken}`;
    // }
    axiosConfig.headers["Content-Type"] = "application/json";
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

/*
  Response Interceptor
  Responsibilities:
  1- If api sends 401 token then send user to login page
*/
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // if auth
    // if (get(error, "response.status", "") === 401) {
    //   // reload will take user to Login page.
    //   setSession(null, null);
    //   window.location.reload();
    // } else {
    return Promise.reject(error);
    // }
  }
);

export default axiosInstance;
