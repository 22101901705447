import React from "react";
import { Link, Grid, Button, Toolbar, AppBar } from "@mui/material";

function Footer() {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        top: "auto",
        bottom: 0,
        padding: { xs: 0, md: "0 10vw" },
        backgroundColor: "#FFFFFF",
      }}
    >
      <Toolbar>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ borderTop: "1px solid #ccc", padding: "1vh 0" }}
        >
          <Grid item>
            <Link to="#">
              <Button
                color="inherit"
                sx={{ textTransform: "none", fontSize: 17 }}
              >
                © 2023 Ford Motor Company
              </Button>
            </Link>

            <Link to="#">
              <Button
                color="inherit"
                sx={{ textTransform: "none", fontSize: 17 }}
              >
                Copyright
              </Button>
            </Link>

            <Link to="#">
              <Button
                color="inherit"
                sx={{ textTransform: "none", fontSize: 17 }}
              >
                Privacy Statement
              </Button>
            </Link>

            <Link to="#">
              <Button
                color="inherit"
                sx={{ textTransform: "none", fontSize: 17 }}
              >
                Your CA Privacy Rights
              </Button>
            </Link>

            <Link to="#">
              <Button
                color="inherit"
                sx={{ textTransform: "none", fontSize: 17 }}
              >
                Terms & Conditions
              </Button>
            </Link>

            <Link to="#">
              <Button
                color="inherit"
                sx={{ textTransform: "none", fontSize: 17 }}
              >
                Accessibility
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="#"
              rel="noopener noreferrer"
              color="text.primary"
              sx={{ float: "right" }}
            >
              <img
                src="https://www.ford.com/finance/apply/assets/images/ford/ford_logo.svg"
                alt="Ford Go Further"
                title="Ford Go Further"
                width="93px"
                height="32px"
                draggable="false"
              />
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
