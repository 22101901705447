import React, { useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { USStates } from '../../utils/constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axios from '../../utils/axios';
import CustomToast from '../CustomToast';

function StepThreeContent({
  states,
  changeHandler,
  activeStep,
  handleBack,
  handleNext,
  dateChangeHandler,
}) {
  const initialValues = {
    firstName: states.firstName ?? '',
    middleName: states.middleName ?? '',
    lastName: states.lastName ?? '',
    dob: states.dob ? moment(new Date(states.dob)) : moment(new Date()),
    socialSecurityNo: states.socialSecurityNo ?? '',
    phone: states.phone ?? '',
    email: states.email ?? '',
    confirmEmail: states.confirmEmail ?? '',
    homeAddress: states.homeAddress ?? '',
    apt: states.apt ?? '',
    city: states.city ?? '',
    state: states.state ?? '',
    zipCode: states.zipCode ?? '',
    currentEmployer: states.currentEmployer ?? '',
    currentIncome: states.currentIncome ?? '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    middleName: Yup.string().optional(),
    lastName: Yup.string().required('Required'),
    currentEmployer: Yup.string().required('Required'),
    currentIncome: Yup.string().required('Required'),
    homeAddress: Yup.string().required('Required'),
    apt: Yup.string().optional(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zipCode: Yup.number().required('Required'),
    dob: Yup.string().required('Required'),
    socialSecurityNo: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'SSN should be min 4 numbers')
      .max(9, 'SSN should be max 9 numbers')
      .required('Required'),
    phone: Yup.string()
      .min(9, 'Phone should be min 9 numbers')
      .max(13, 'Phone should be max 13 numbers')
      .required('Required'),
    email: Yup.string()
      .email('Invalid email format') // Validates email format
      .required('Required'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Emails must match') // Compares with the 'email' field
      .required('Required'),
  });

  const handleChangeData = (states, values) => {
    const state = {
      user_id: states.uuid,
      year: parseInt(states.selectedYear),
      model: states.selectedModel,
      trim: states.selectedTrim,
      finance_type: states.financeType,
      application_type: states.applicationType,
      down_payment_amount: parseInt(states.downPayment ?? '0'),
      first_name: values.firstName,
      middle_name: values.middleName,
      last_name: values.lastName,
      current_employer: values.currentEmployer,
      current_income: parseInt(values.currentIncome),
      home_address: values.homeAddress,
      appartment: values.apt,
      city: values.city,
      state: values.state,
      zip_code: parseInt(values.zipCode),
      ssn: parseInt(values.socialSecurityNo),
      dob: moment(values.dob).format('yyyy-MM-DD'),
      phone_number: values.phone,
      email: values.email,
      confirm_email: values.confirmEmail,
    };
    return state;
  };

  const handleSubmit = useCallback(
    async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
      const newState = await handleChangeData(states, values);
      console.log(newState);
      try {
        const { data } = await axios.post('api/logForm', newState);
        console.log(data);
        if (data.status) {
          localStorage.setItem('userID', data.data.user_id);
          localStorage.setItem('userEmail', data.data.email);
          handleNext({
            values,
            resetForm,
            setErrors,
            setStatus,
            setSubmitting,
          });
          CustomToast('success', data.message);
        } else {
          CustomToast('error', data.message);
        }
      } catch (e) {
        CustomToast('error', e.message);
      }
    },
    [handleNext, states]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        touched,
        status,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent='center'
                alignItems='flex-start'
                spacing={5}
              >
                <Grid item xs={12} md={5}>
                  <Stack
                    direction='column'
                    justifyContent='center'
                    sx={{ textAlign: 'center' }}
                  >
                    {states.imageURL && (
                      <Box
                        component={'img'}
                        src={states.imageURL}
                        alt='img'
                        sx={{
                          maxWidth: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    )}
                    {states.selectedCarPrice !== null && (
                      <>
                        <Typography variant='h6'>
                          {`${states.selectedYear} ${states.selectedModel} ${states.selectedTrim}`}
                          <br />
                        </Typography>
                        <Typography variant='body2'>
                          MSRP ${states.selectedCarPrice}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Grid
                    container
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing={3}
                  >
                    <Grid item xs={12} lg={4}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='firstName'>First Name*</FormLabel>
                        <TextField
                          name='firstName'
                          id='firstName'
                          placeholder='First Name'
                          value={values.firstName}
                          size='small'
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(
                            touched.firstName ? errors.firstName : false
                          )}
                          helperText={touched.firstName ? errors.firstName : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='middleName'>Middle Name</FormLabel>
                        <TextField
                          name='middleName'
                          id='middleName'
                          placeholder='Middle Name'
                          value={values.middleName}
                          size='small'
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(
                            touched.middleName ? errors.middleName : false
                          )}
                          helperText={
                            touched.middleName ? errors.middleName : ''
                          }
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='lastName'>Last Name*</FormLabel>
                        <TextField
                          name='lastName'
                          id='lastName'
                          placeholder='Last Name'
                          value={values.lastName}
                          size='small'
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(
                            touched.lastName ? errors.lastName : false
                          )}
                          helperText={touched.lastName ? errors.lastName : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='currentEmployer'>
                          Current Employer*
                        </FormLabel>
                        <TextField
                          name='currentEmployer'
                          id='currentEmployer'
                          placeholder='Current Employer'
                          value={values.currentEmployer}
                          size='small'
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(
                            touched.currentEmployer
                              ? errors.currentEmployer
                              : false
                          )}
                          helperText={
                            touched.currentEmployer
                              ? errors.currentEmployer
                              : ''
                          }
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='currentIncome'>
                          Current Income*
                        </FormLabel>
                        <TextField
                          name='currentIncome'
                          id='currentIncome'
                          placeholder='Current Income'
                          value={values.currentIncome}
                          size='small'
                          onChange={(e) => {
                            if (e.target.value) {
                              changeHandler(e);
                              setFieldValue(
                                'currentIncome',
                                e.target.value.replace(/[^0-9]/g, '')
                              );
                            } else {
                              setFieldValue('currentIncome', '');
                            }
                          }}
                          error={Boolean(
                            touched.currentIncome ? errors.currentIncome : false
                          )}
                          helperText={
                            touched.currentIncome ? errors.currentIncome : ''
                          }
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='homeAddress'>
                          Home Address*
                        </FormLabel>
                        <TextField
                          name='homeAddress'
                          id='homeAddress'
                          size='small'
                          placeholder='Home Address'
                          value={values.homeAddress}
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(
                            touched.homeAddress ? errors.homeAddress : false
                          )}
                          helperText={
                            touched.homeAddress ? errors.homeAddress : ''
                          }
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='apt'>Apt # (optional)</FormLabel>
                        <TextField
                          name='apt'
                          id='apt'
                          size='small'
                          placeholder='Appartment Number'
                          value={values.apt}
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(touched.apt ? errors.apt : false)}
                          helperText={touched.apt ? errors.apt : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='state'>State*</FormLabel>
                        <FormControl
                          variant='outlined'
                          fullWidth
                          size='small'
                          error={touched.state && !values.state}
                        >
                          <Select
                            name='state'
                            id='state'
                            value={values.state}
                            onChange={(e) => {
                              handleChange(e);
                              changeHandler(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {USStates.map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.state && !values.state ? (
                            <FormHelperText>{errors.state}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='city'>City*</FormLabel>
                        <TextField
                          name='city'
                          id='city'
                          size='small'
                          placeholder='City'
                          value={values.city}
                          disabled={!values.state}
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(touched.city ? errors.city : false)}
                          helperText={touched.city ? errors.city : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='zipCode'>Zip Code*</FormLabel>
                        <TextField
                          name='zipCode'
                          id='zipCode'
                          size='small'
                          placeholder='Zip Code'
                          value={values.zipCode}
                          onChange={(e) => {
                            if (e.target.value) {
                              changeHandler(e);
                              setFieldValue(
                                'zipCode',
                                e.target.value.replace(/[^0-9]/g, '')
                              );
                            } else {
                              setFieldValue('zipCode', '');
                            }
                          }}
                          error={Boolean(
                            touched.zipCode ? errors.zipCode : false
                          )}
                          helperText={touched.zipCode ? errors.zipCode : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='socialSecurityNo'>
                          Social Security Number*
                        </FormLabel>
                        <TextField
                          name='socialSecurityNo'
                          id='socialSecurityNo'
                          placeholder='###-##-####'
                          value={values.socialSecurityNo}
                          sx={{ '&-webkit-appearance': 'none' }}
                          size='small'
                          onChange={(e) => {
                            if (e.target.value) {
                              changeHandler(e);
                              setFieldValue(
                                'socialSecurityNo',
                                e.target.value.replace(/[^0-9]/g, '')
                              );
                            } else {
                              setFieldValue('socialSecurityNo', '');
                            }
                          }}
                          error={Boolean(
                            touched.socialSecurityNo
                              ? errors.socialSecurityNo
                              : false
                          )}
                          helperText={
                            touched.socialSecurityNo
                              ? errors.socialSecurityNo
                              : ''
                          }
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} mb={3}>
                      <Stack spacing={0}>
                        <FormLabel htmlFor='dob'>Date of Birth*</FormLabel>
                        <Grid
                          container
                          spacing={1}
                          justifyContent='space-between'
                          mt={0.5}
                        >
                          <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                name='dob'
                                views={['month']}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ height: '10px' }}
                                disableFuture
                                value={values.dob ?? null}
                                onChange={(newValue) => {
                                  dateChangeHandler(moment(new Date(newValue)));
                                  setFieldValue(
                                    'dob',
                                    moment(new Date(newValue))
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                name='dob'
                                views={['day']}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ height: '10px' }}
                                disableFuture
                                value={values.dob ?? null}
                                onChange={(newValue) => {
                                  dateChangeHandler(moment(new Date(newValue)));
                                  setFieldValue(
                                    'dob',
                                    moment(new Date(newValue))
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                name='dob'
                                views={['year']}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ height: '10px' }}
                                disableFuture
                                value={values.dob ?? null}
                                onChange={(newValue) => {
                                  dateChangeHandler(moment(new Date(newValue)));
                                  setFieldValue(
                                    'dob',
                                    moment(new Date(newValue))
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='phone'>Phone Number*</FormLabel>
                        <TextField
                          name='phone'
                          id='phone'
                          placeholder='(###) ### - ####'
                          value={values.phone}
                          size='small'
                          onChange={(e) => {
                            if (e.target.value) {
                              changeHandler(e);
                              setFieldValue(
                                'phone',
                                e.target.value.replace(/[^0-9]/g, '')
                              );
                            } else {
                              setFieldValue('phone', '');
                            }
                          }}
                          error={Boolean(touched.phone ? errors.phone : false)}
                          helperText={touched.phone ? errors.phone : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='email'>Email*</FormLabel>
                        <TextField
                          name='email'
                          id='email'
                          placeholder='example@example.com'
                          value={values.email}
                          type='email'
                          size='small'
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(touched.email ? errors.email : false)}
                          helperText={touched.email ? errors.email : ''}
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={1}>
                        <FormLabel htmlFor='confirmEmail'>
                          Confirm Email*
                        </FormLabel>
                        <TextField
                          name='confirmEmail'
                          id='confirmEmail'
                          placeholder='example@example.com'
                          value={values.confirmEmail}
                          type='email'
                          size='small'
                          onChange={(e) => {
                            handleChange(e);
                            changeHandler(e);
                          }}
                          error={Boolean(
                            touched.confirmEmail ? errors.confirmEmail : false
                          )}
                          helperText={
                            touched.confirmEmail ? errors.confirmEmail : ''
                          }
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction='row'
                justifyContent='flex-end'
                spacing={2}
                mt={5}
              >
                <Button
                  variant='contained'
                  onClick={handleBack}
                  disabled={isSubmitting || activeStep === 0}
                  sx={{ textTransform: 'none', minWidth: { xs: 119, md: 150 } }}
                >
                  Previous
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                  sx={{ textTransform: 'none', minWidth: { xs: 119, md: 150 } }}
                >
                  Next
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default StepThreeContent;
