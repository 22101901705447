import React, { useEffect, useMemo } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
// Dummy data for dropdown options
const years = ["2022", "2023", "2024"];
const fordModels = [
  "Explorer",
  "Explorer Hybrid",
  "F-150",
  "Mustang",
  "Super Duty",
];
const trimOptions = {
  Explorer: [
    "XLT",
    "ST-Line",
    "Timberline",
    "Limited",
    "ST",
    "King Ranch",
    "Platinum",
  ],
  "Explorer Hybrid": [
    "XLT",
    "ST-Line",
    "Timberline",
    "Limited",
    "ST",
    "King Ranch",
    "Platinum",
  ],
  "F-150": ["XL", "XLT", "Lariat", "King Ranch", "Platinum", "Limited"],
  Mustang: ["EcoBoost", "GT", "Mach 1", "Shelby GT500"],
  "Super Duty": ["XL", "XLT", "Lariat", "King Ranch", "Platinum", "Limited"],
};
function VehicleForm({
  states,
  defaultImageURL,
  changeHandler,
  handleBack,
  activeStep,
  handleNext,
}) {
  const modelImageUrls = useMemo(() => {
    return {
      Explorer:
        "https://build.ford.com/dig/Ford/Explorer/2022/HD-TILE/Image[%7CFord%7CExplorer%7C2022%7C1%7C1.%7C200A...PUM..888.89N.86E.SRB.64X.99H.XLT.]/EXT/5/vehicle.png",
      "Explorer Hybrid":
        "https://build.ford.com/dig/Ford/Explorer%20Hybrid/2022/HD-TILE/Image[%7CFord%7CExplorer%20Hybrid%7C2022%7C1%7C1.%7C310A...PUM..88C.89N.86E.SRB.64U.65S.99W.61N.LTD.]/EXT/5/vehicle.png",
      "F-150":
        "https://build.ford.com/dig/Ford/F-150%20F-150/2022/HD-TILE/Image[%7CFord%7CF-150%20F-150%7C2022%7C1%7C1.%7C600A..145.PUM.SD1.88K.89J.A5GAA.SS5.924.435.BLB.55D.SPP.60P.53B.CCAB.64M.RWD.995.67T.50N.43B.91B.471.583.524.62W.59S.77K.]/EXT/5/vehicle.png",
      Mustang:
        "https://build.ford.com/dig/Ford/Mustang/2022/HD-TILE/Image[%7CFord%7CMustang%7C2022%7C1%7C1.%7C200A...PG1..88E.891.13K.COU.59C.646.99H.SY3.44X.EBST.]/EXT/5/vehicle.png",
      "Super Duty":
        "https://build.ford.com/dig/Ford/SuperDuty/2022/HD-TILE/Image[%7CFord%7CSuperDuty%7C2022%7C1%7C1.%7C..160.PUM..887.89B.DEC.PLA.924.435.43B.16F.18E.54F.53R.66B.CRW.SRW.64U.4X4.996.52B.213.527.21N.60B.76R.43C.SY4.874.59Q.250.]/EXT/5/vehicle.png",
    };
  }, []);

  const initialValues = {
    selectedYear: states.selectedYear,
    selectedModel: states.selectedModel,
    selectedTrim: states.selectedTrim,
  };

  const validationSchema = Yup.object().shape({
    selectedYear: Yup.string().required("Required"),
    selectedModel: Yup.string().required("Required"),
    selectedTrim: Yup.string().required("Required"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    handleNext({
      values,
      resetForm,
      setErrors,
      setStatus,
      setSubmitting,
    });
  };

  useEffect(() => {
    // Update the image URL whenever any of the dropdowns change
    if (states.selectedYear && states.selectedModel && states.selectedTrim) {
      // Check if the key exists in the modelImageUrls object
      if (modelImageUrls.hasOwnProperty(states.selectedModel)) {
        changeHandler({
          target: {
            name: "imageURL",
            value: modelImageUrls[states.selectedModel],
          },
        });
      }
      // Generate a random price (you can adjust the range as needed)
      const randomPrice = Math.floor(Math.random() * 100000) + 30000; // Random price between 30000 and 130000
      changeHandler({
        target: {
          name: "selectedCarPrice",
          value: randomPrice,
        },
      });
    }
  }, [
    modelImageUrls,
    states.selectedYear,
    states.selectedModel,
    states.selectedTrim,
    changeHandler,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        touched,
        status,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}></Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={touched.selectedYear && !values.selectedYear}
                >
                  <InputLabel htmlFor="year">Select Year</InputLabel>
                  <Select
                    label="Select Year"
                    value={states.selectedYear}
                    onChange={(e) => {
                      changeHandler(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="selectedYear"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.selectedYear && !values.selectedYear ? (
                    <FormHelperText>{errors.selectedYear}</FormHelperText>
                  ) : (
                    <FormHelperText> </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={touched.selectedModel && !values.selectedModel}
                >
                  <InputLabel htmlFor="model">Select Model</InputLabel>
                  <Select
                    label="Select Model"
                    value={states.selectedModel}
                    disabled={!states.selectedYear}
                    onChange={(e) => {
                      changeHandler(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="selectedModel"
                  >
                    {fordModels.map((model) => (
                      <MenuItem key={model} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.selectedModel && !values.selectedModel ? (
                    <FormHelperText>{errors.selectedModel}</FormHelperText>
                  ) : (
                    <FormHelperText> </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={touched.selectedTrim && !values.selectedTrim}
                >
                  <InputLabel htmlFor="trim">Select Trim</InputLabel>
                  <Select
                    label="Select Trim"
                    value={states.selectedTrim}
                    disabled={!states.selectedModel}
                    onChange={(e) => {
                      changeHandler(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="selectedTrim"
                  >
                    {states.selectedModel &&
                      trimOptions[states.selectedModel].map((trim) => (
                        <MenuItem key={trim} value={trim}>
                          {trim}
                        </MenuItem>
                      ))}
                  </Select>
                  {touched.selectedTrim && !values.selectedTrim ? (
                    <FormHelperText>{errors.selectedTrim}</FormHelperText>
                  ) : (
                    <FormHelperText> </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                pt: states.imageURL === defaultImageURL ? "10vh !important" : 0,
              }}
            >
              {states.imageURL && (
                <Box
                  component={"img"}
                  src={states.imageURL}
                  alt="img"
                  sx={{
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              {states.selectedCarPrice !== null && (
                <Stack direction="column" justifyContent="center">
                  <Typography variant="h6">
                    {`${states.selectedYear} ${states.selectedModel} ${states.selectedTrim}`}
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    MSRP ${states.selectedCarPrice}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                mt={5}
              >
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ textTransform: "none", minWidth: { xs: 119, md: 150 } }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ textTransform: "none", minWidth: { xs: 119, md: 150 } }}
                >
                  Next
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default VehicleForm;
