import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import StepOneContent from "./StepOneContent";
import StepTwoContent from "./StepTwoContent";
import StepThreeContent from "./StepThreeContent";
import StepFourContent from "./StepFourContent";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { v4 as uuidv4 } from "uuid";
import { ApplicationTypeEnum, FinanceTypeEnum } from "../../utils/constants";

const defaultImageURL =
  "https://www.ford.com/finance/apply/assets/images/ford/GT350_EdgeST_Raptor_lineup_1178x267.png";
const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

const initalStates = {
  selectedYear: "",
  selectedModel: "",
  selectedTrim: "",
  selectedCarPrice: null,
  financeType: FinanceTypeEnum.BUY,
  applicationType: ApplicationTypeEnum.SINGLE,
  downPayment: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  socialSecurityNo: "",
  phone: "",
  email: "",
  confirmEmail: "",
  homeAddress: "",
  currentEmployer: "",
  currentIncome: "",
  imageURL: defaultImageURL,
  uuid: null,
};

function CustomStepper() {
  const [states, setStates] = useState({ ...initalStates });
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const changeHandler = useCallback((e) => {
    setStates((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const dateChangeHandler = useCallback((value) => {
    setStates((prevState) => ({
      ...prevState,
      dob: value,
    }));
  }, []);

  const handleNext = useCallback(
    (params) => {
      if (activeStep < steps.length - 1) {
        setActiveStep(activeStep + 1);
      }
    },
    [activeStep]
  );

  const handleBack = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  const stepContent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <StepOneContent
            states={states}
            changeHandler={changeHandler}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
          />
        );
      case 1:
        return (
          <StepTwoContent
            states={states}
            changeHandler={changeHandler}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <StepThreeContent
            states={states}
            changeHandler={changeHandler}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            dateChangeHandler={dateChangeHandler}
          />
        );
      case 3:
        return (
          <StepFourContent
            activeStep={activeStep}
            handleBack={handleBack}
            steps={steps}
          />
        );
      default:
        return null;
    }
  }, [
    activeStep,
    changeHandler,
    handleBack,
    dateChangeHandler,
    handleNext,
    states,
  ]);

  useEffect(() => {
    if (!states.uuid) {
      const timestamp = uuidv4();
      localStorage.clear();
      setStates({ ...states, uuid: timestamp });
    }
  }, [states]);

  const stepHeading = () => {
    switch (activeStep) {
      case 0:
        return <Typography variant="h6">Step 1: Select Vehicle</Typography>;
      case 1:
        return (
          <Typography variant="h6">Step 2: Finance Information</Typography>
        );
      case 2:
        return (
          <Typography variant="h6">Step 3: Personal Information</Typography>
        );
      case 3:
        return <Typography variant="h6">Step 4: Verify Yourself</Typography>;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={5}
      sx={{
        mb: 20,
        padding: "2vh 10vw",
        "@media (max-width: 600px)": {
          mb: 50,
        },
      }}
    >
      <Grid item xs={12}>
        <Typography variant={isSmallScreen ? "h5" : "h4"}>
          Credit Application - Demo
        </Typography>
        {stepHeading()}
      </Grid>
      <Grid item xs={12}>
        <ProgressBar
          percent={(activeStep / (steps.length - 1)) * 90 + 10}
          filledBackground="#0678BC"
          height={20}
        />
        <Typography
          sx={{ fontSize: 15, fontWeight: "bold", textAlign: "center", pt: 1 }}
        >
          Completing Step {activeStep + 1} of {steps.length}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column">{stepContent()}</Stack>
      </Grid>
    </Grid>
  );
}

export default CustomStepper;
