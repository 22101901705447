import React from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApplicationTypeEnum, FinanceTypeEnum } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  financeOption: {
    cursor: "pointer",
    padding: 15,
    border: "1px solid #ccc",
    textAlign: "center",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#102B4E",
      color: "#fff",
    },
  },
  selectedFinanceOption: {
    backgroundColor: "#102B4E",
    color: "#fff",
  },
}));

function StepTwoContent({
  states,
  activeStep,
  handleBack,
  handleNext,
  changeHandler,
}) {
  const classes = useStyles();
  const selectedCarPrice = localStorage.getItem("selectedCarPrice");

  const handleDownPaymentChange = (value) => {
    changeHandler({
      target: {
        name: "downPayment",
        value: value,
      },
    });
  };

  const handleFinanceTypeChange = (type) => {
    changeHandler({
      target: {
        name: "financeType",
        value: type,
      },
    });
  };

  const handleApplicationTypeChange = (type) => {
    changeHandler({
      target: {
        name: "applicationType",
        value: type,
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" spacing={5}>
          <Grid item xs={12} md={5}>
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              {states.imageURL && (
                <Box
                  component={"img"}
                  src={states.imageURL}
                  alt="img"
                  sx={{
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
              {selectedCarPrice !== null && (
                <>
                  <Typography variant="h6">
                    {`${states.selectedYear} ${states.selectedModel} ${states.selectedTrim}`}
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    MSRP ${selectedCarPrice}
                  </Typography>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Finance Type
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  className={`${classes.financeOption} ${
                    states.financeType === FinanceTypeEnum.BUY
                      ? classes.selectedFinanceOption
                      : ""
                  }`}
                  onClick={() => handleFinanceTypeChange(FinanceTypeEnum.BUY)}
                >
                  Buy
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  className={`${classes.financeOption} ${
                    states.financeType === FinanceTypeEnum.LEASE
                      ? classes.selectedFinanceOption
                      : ""
                  }`}
                  onClick={() => handleFinanceTypeChange(FinanceTypeEnum.LEASE)}
                >
                  Lease
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Application Type
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  className={`${classes.financeOption} ${
                    states.applicationType === ApplicationTypeEnum.SINGLE
                      ? classes.selectedFinanceOption
                      : ""
                  }`}
                  onClick={() =>
                    handleApplicationTypeChange(ApplicationTypeEnum.SINGLE)
                  }
                >
                  Single
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  className={`${classes.financeOption} ${
                    states.applicationType === ApplicationTypeEnum.JOINT
                      ? classes.selectedFinanceOption
                      : ""
                  }`}
                  onClick={() =>
                    handleApplicationTypeChange(ApplicationTypeEnum.JOINT)
                  }
                >
                  Joint
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Down Payment (optional)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="downPayment"
                  placeholder="None"
                  value={states.downPayment}
                  size="small"
                  onChange={(e) => {
                    if (e.target.value) {
                      handleDownPaymentChange(
                        e.target.value.replace(/[^0-9]/g, "")
                      );
                    } else {
                      handleDownPaymentChange("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end" spacing={2} mt={5}>
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ textTransform: "none", minWidth: { xs: 119, md: 150 } }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            sx={{ textTransform: "none", minWidth: { xs: 119, md: 150 } }}
          >
            Next
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default StepTwoContent;
