import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Tooltip,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Paper,
  Grid,
  Chip,
  Divider,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LinkIcon from "@mui/icons-material/Link";
import axios from "../../utils/axios";
import CustomToast from "../CustomToast";

function StepFourContent({ activeStep, steps, handleBack, handleNext }) {
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [emailTooltipOpen, setEmailTooltipOpen] = useState(false);
  const theme = useTheme();
  const userId = localStorage.getItem("userID");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const incodeVerificationLink = `${
    process.env.REACT_APP_INCODE_FRONTEND_URL
  }?userID=${localStorage.getItem("userID")}`;

  const handleCopyClick = () => {
    setCopyTooltipOpen(true);
    navigator.clipboard.writeText(incodeVerificationLink.toString());
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 1500);
  };

  const socketConnection = useCallback(() => {
    const stagingSocketUrl = "wss://ford-backend.verifinow.io";
    const ws = new WebSocket(stagingSocketUrl);

    ws.onopen = () => {
      console.log("Connected to the server");
    };
    ws.onerror = (e) => {
      console.log("OnError =>", e);
      ws.close();
    };
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log("itself socket =>", data);
      if (data.data.userId === userId) {
        setVerificationStatus(data.data.verificationStatus);
      }
    };
    ws.onclose = (e) => {
      console.log("Disconnected. Check internet or server.", e);
      socketConnection();
    };
  }, [userId]);
  useEffect(() => {
    socketConnection();
  }, [socketConnection]);

  const handleEmailClick = async () => {
    try {
      const { data } = await axios.post(
        "api/sendVerificationLink",
        JSON.stringify({ userId: localStorage.getItem("userID") })
      );
      if (data.status) {
        CustomToast("success", "Email sent successfully");
      } else {
        CustomToast("error", data.message);
      }
    } catch (e) {
      CustomToast("error", e.message);
    } finally {
      setTimeout(() => {
        setEmailTooltipOpen(false);
      }, 1500);
    }
  };
  function capitalizeFirstLetter(inputString) {
    if (inputString.length === 0) {
      return inputString;
    }
    let formattedString = "";

    const newInputString = inputString.split(" ");
    for (let i = 0; i < newInputString.length; i++) {
      formattedString +=
        newInputString[i].charAt(0).toUpperCase() + newInputString[i].slice(1);
      formattedString += " ";
    }
    return formattedString;
  }
  const addSpaceBetweenLetters = (inputString) => {
    let formattedString = "";
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] === inputString[i].toUpperCase() && i > 0) {
        formattedString += " ";
      }
      formattedString += inputString[i].toLowerCase();
    }

    return capitalizeFirstLetter(formattedString);
  };
  return (
    <Stack spacing={3}>
      <Stack alignItems="center">
        {!verificationStatus ? (
          <Box sx={{ maxWidth: "768px" }}>
            <Typography
              variant={"h6"}
              sx={{ textAlign: { xs: "left", md: "center" }, fontWeight: 600 }}
            >
              Ensuring your security is our top priority. In just a few simple
              steps, we'll validate your identity, ensuring a swift and seamless
              loan application process
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ marginTop: 6, fontWeight: 600 }}
            >
              Verify via Mobile (Recommended)
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              mt={5}
              sx={{
                gap: 3,
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  gap: 3,
                },
              }}
            >
              <Box
                display="inline-flex"
                sx={{
                  background: "#F7F8F9",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  display="inline-flex"
                  sx={{
                    padding: "5px 10px",
                    alignItems: "center",
                    borderRight: "1px solid #C0C0C04D",
                  }}
                >
                  <LinkIcon sx={{ rotate: "135deg", color: "#8E8EA9" }} />
                </Box>
                <Box
                  display="inline-flex"
                  sx={{
                    padding: "5px 10px",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  <Tooltip
                    title={incodeVerificationLink}
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#8E8EA9",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {incodeVerificationLink}
                    </Typography>
                  </Tooltip>
                </Box>
                <Tooltip
                  title={copyTooltipOpen ? "Link Copied" : ""}
                  placement="top"
                  arrow
                >
                  <Button variant="contained" onClick={handleCopyClick}>
                    Copy
                  </Button>
                </Tooltip>
              </Box>
              <Box
                display="inline-flex"
                sx={{
                  background: "#F7F8F9",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  display="inline-flex"
                  sx={{
                    padding: "5px 10px",
                    borderRight: "1px solid #C0C0C04D",
                  }}
                >
                  <EmailOutlinedIcon sx={{ color: "#8E8EA9" }} />
                </Box>
                <Box
                  display="inline-flex"
                  sx={{
                    padding: "5px 10px",
                    minWidth: 200,
                  }}
                >
                  <Typography variant="body2" sx={{ color: "#8E8EA9" }}>
                    {localStorage.getItem("userEmail")}
                  </Typography>
                </Box>
                <Tooltip
                  title={emailTooltipOpen ? "Email Sent" : ""}
                  arrow
                  placement="top"
                >
                  <Button variant="contained" onClick={handleEmailClick}>
                    Email
                  </Button>
                </Tooltip>
              </Box>
            </Stack>
            {/* <Typography variant={isSmallScreen ? "h6" : "h4"}>
        Start Verification
      </Typography>
      <Link to="" sx={{ textAlign: { xs: "center", md: "left" } }}>
        Click here to verify
      </Link> */}
          </Box>
        ) : (
          <Paper elevation={2} sx={{ padding: "40px" }}>
            <Typography variant="h6" mb={1} sx={{ textAlign: "center" }}>
              Overall Verification Status:{" "}
              {verificationStatus.overallValidationStatus === "PASSED" ? (
                <Chip
                  label={verificationStatus.overallValidationStatus}
                  color="success"
                  variant="outlined"
                  sx={{ border: "none" }}
                />
              ) : (
                <Chip
                  label={verificationStatus.overallValidationStatus}
                  color="error"
                  variant="outlined"
                  sx={{ border: "none" }}
                />
              )}
            </Typography>
            <Divider sx={{ margin: 2 }} />
            <Grid container spacing={2}>
              {Object.keys(verificationStatus).map((key) =>
                key !== "overallValidationStatus" ? (
                  <>
                    <Grid item xs={6}>
                      {addSpaceBetweenLetters(key)}:
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      {verificationStatus[key] === "PASSED" ? (
                        <Chip
                          label={verificationStatus[key]}
                          color="success"
                          variant="outlined"
                          sx={{ border: "none" }}
                        />
                      ) : (
                        <Chip
                          label={verificationStatus[key]}
                          color="error"
                          variant="outlined"
                          sx={{ border: "none" }}
                        />
                      )}
                    </Grid>
                  </>
                ) : null
              )}
            </Grid>
          </Paper>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        pt={isSmallScreen ? 0 : 20}
      >
        <Button
          variant="contained"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ textTransform: "none", minWidth: { xs: 119, md: 150 } }}
        >
          Previous
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{ textTransform: "none", minWidth: { xs: 119, md: 150 } }}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Stack>
    </Stack>
  );
}

export default StepFourContent;
