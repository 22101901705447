import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{
        borderBottom: "1px solid #ccc",
        padding: { xs: "1vh 2vw", md: "2vh 2vw" },
      }}
    >
      <Toolbar>
        <Link href="#">
          <img
            src="https://www.ford.com/finance/apply/assets/images/ford/ford_logo.svg"
            alt="Ford Logo"
            width="100%"
            height="35px"
          />
        </Link>
        <IconButton
          color="inherit"
          onClick={toggleDrawer}
          sx={{ display: { sm: "block", md: "none" }, ml: "auto" }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <List
          sx={{ display: { xs: "none", md: "flex" }, ml: 5, width: "100%" }}
        >
          <Link href="#">
            <Button
              color="inherit"
              sx={{ textTransform: "none", fontSize: 17 }}
            >
              Vehicles
            </Button>
          </Link>
          <Link href="#">
            <Button
              color="inherit"
              sx={{ textTransform: "none", fontSize: 17 }}
            >
              Shop
            </Button>
          </Link>
          <Link href="#">
            <Button
              color="inherit"
              sx={{ textTransform: "none", fontSize: 17 }}
            >
              Finance
            </Button>
          </Link>
          <Link href="#">
            <Button
              color="inherit"
              sx={{ textTransform: "none", fontSize: 17 }}
            >
              Owner
            </Button>
          </Link>
          <Box sx={{ ml: { sx: 0, md: "auto" }, display: "flex", gap: 1 }}>
            <Link
              href="#"
              sx={{
                gap: 1,
                display: "flex",
                textDecoration: "none",
                alignItems: "center",
              }}
            >
              <img
                src="https://www.ford.com/finance/apply/assets/images/ford/pin.svg"
                alt="Locate"
                height="25px"
                width="25px"
                sx={{ pr: 2 }}
              />
              <span className="text-primary header-action">
                Locate a Dealer
              </span>
            </Link>
            <Link
              href="#"
              sx={{
                gap: 1,
                display: "flex",
                textDecoration: "none",
                alignItems: "center",
              }}
            >
              <img
                src="https://www.ford.com/finance/apply/assets/images/ford/magnifying_glass.svg"
                alt="Search"
                height="25px"
                width="25px"
                sx={{ pl: 3, pr: 0 }}
              />
            </Link>

            <Button
              sx={{
                gap: 1,
                display: "flex",
                textDecoration: "none",
                alignItems: "center",
              }}
            >
              <img
                src="https://www.ford.com/finance/apply/assets/images/ford/globe.svg"
                alt="Select Language"
                height="25px"
                width="25px"
                sx={{ mr: 2 }}
              />
              <span className="text-primary header-action">English</span>
            </Button>
          </Box>
        </List>
      </Toolbar>

      {/* Drawer for small devices */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{ "& .MuiDrawer-paper": { width: 240 } }}
      >
        <List>
          <ListItem button component="a" href="https://www.ford.com/">
            <ListItemText primary="Vehicles" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="https://shop.ford.com/showroom/?gnav=header-shop&linktype=build#/"
          >
            <ListItemText primary="Shop" />
          </ListItem>
          <ListItem button component="a" href="https://www.ford.com/finance">
            <ListItemText primary="Finance" />
          </ListItem>
          <ListItem button component="a" href="https://owner.ford.com/">
            <ListItemText primary="Owner" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="https://owner.ford.com/service/dealer-locator.html/"
          >
            <ListItemText primary="Locate a Dealer" />
          </ListItem>
          <ListItem button component="a" href="https://www.ford.com/search/">
            <ListItemText primary="Search" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Select Language" />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}

export default Header;
